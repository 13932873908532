import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MobileSideMenu from './MobileSideMenu';
import { Button } from 'primereact/button';
import { ContextMenu } from 'primereact/contextmenu';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const store = useStore();
    const user = store.getState().app.user.user;
    const [showSidemenu, setShowSidemenu] = useState(false);
    const profileCM = useRef(null);
    const profileItems = [
        {
            label: t('profile'),
            command: () => {
                navigate('/' + user.role + '/profile')
            }
        },
        {
            label: 'English',
            command: () => {
                updateAppLang('en');
            }
        },
        {
            label: 'عربي',
            command: () => {
                updateAppLang('ar');
            }
        },
        {
            label: t('logout'),
            command: () => {
                logoutUser();
            }
        }
    ];
    const logoutUser = () => {
        localStorage.removeItem('_jwt');
        window.location.assign('/');
    }
    const updateAppLang = (newLang) => {
        localStorage.setItem('lang', newLang);
        window.location.reload();
    }
    const hideSidemenu = () => {
        setShowSidemenu(false);
    }
    return (
        <div>
            <div>
                <Offcanvas
                    className="mobile-side-menu-offcanvas"
                    show={showSidemenu}
                    onHide={() => {
                        setShowSidemenu(!showSidemenu);
                    }}
                >
                    <Offcanvas.Body>
                        <div className="text-white">
                            <h6>{t('hello')} {t(user.role)} {t('welcome_to')}</h6>
                            <h1>TA-PAL</h1>
                        </div>
                        <div className='mobile-side-menu'>
                            <MobileSideMenu hideSidemenu={() => {
                                hideSidemenu();
                            }} />
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <div className="top-header">
                    <div className='header-content d-flex jcsb align-items-baseline'>
                        <div>
                            {window.location.pathname !== "/" &&
                                <Button
                                    className='icon-btn' severity='secondary'
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    <span className="material-symbols-outlined">
                                        {
                                            document.documentElement.dir === 'rtl' ? 'arrow_forward' : 'arrow_back'
                                        }
                                    </span>
                                </Button>
                            }
                            {window.location.pathname === "/" &&
                                <h4>Welcome to, TA-PAL</h4>
                            }
                        </div>
                        <div className='d-flex'>
                            <ContextMenu model={profileItems} ref={profileCM} breakpoint="767px" />
                            <Button
                                className='icon-btn mx-1' severity='secondary'
                                onClick={(e) => profileCM.current.show(e)}
                                id="profile-btn"
                            >
                                <span className="material-symbols-outlined">
                                    person
                                </span>
                            </Button>
                            {
                                user.role === "qa" &&
                                <div className='d-one d-lg-block'>
                                    <Link to="/communicate" className='link-icon-btn'>
                                        <span className="material-symbols-outlined">
                                            chat
                                        </span>
                                    </Link>
                                </div>
                            }

                            <div className='d-lg-none'>
                                <Button
                                    className=' icon-btn mx-1' severity='secondary'
                                    onClick={(e) => setShowSidemenu(true)}
                                    id="profile-btn"
                                >
                                    <span className="material-symbols-outlined">
                                        menu
                                    </span>
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;
