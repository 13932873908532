import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { getRegisterData, checkEmail, sendOTP, registerUser, getCollegesByCountries } from './../../apis/services';
import { Checkbox } from "primereact/checkbox";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { InputOtp } from 'primereact/inputotp';
import Select from 'react-select';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
const StudentRegister = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [colleges, setColleges] = useState([]);
    const [tc, setTc] = useState(false);
    const [selectedCollege, setSelectedCollege] = useState([]);
    const [majors, setMajors] = useState([]);
    const [major, setMajor] = useState([]);
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOtp] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState('');
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        loadRegisterData();
    }, []);
    const loadRegisterData = async () => {
        try {
            const res = await getRegisterData();
            setMajors(res.data.majors)
            setCountries(res.data.countries);
        } catch (error) {

        }
    }
    const triggerOTP = () => {
        if (tc) {
            setShowOTP(true);
            sendOTP({ email: email })
        }
    }
    const handleRegisterUser = async () => {
        try {
            let data = {
                userData: {
                    user: {
                        name: name,
                        nickName: name,
                        phone: phone,
                        email: email,
                        fcm: '',
                        role: 'student',
                        password: password,
                        country: JSON.stringify(country.id),
                    },
                    college: selectedCollege.id,
                    major: major.id,
                },
                otp: otp,
                bypassOTP: process.env.REACT_APP_BYPASS_OTP,
            }
            setLoading(true);
            const res = await registerUser(data);
            setLoading(false);
            if (res.data.status === undefined || res.data.status === false) {
                alert(t('wrong_otp'));
            } else {
                setShowOTP(false);
                setShowLogin(true);
            }
        } catch (error) {

        }
    }
    const checkUserEmail = async () => {
        try {
            let data = {
                email: email
            }
            const res = await checkEmail(data);
            if (res.data.dont_allow) {
                alert(t('email_exist'));
                setEmail('');
            }
        } catch (error) {
            console.log(error);
        }

    }
    const loadColleges = async (list) => {
        try {
            let data = {
                cids: [list.id],
            }
            const res = await getCollegesByCountries(data);
            setColleges(res.data.colleges);

        } catch (error) {

        }
    }
    return (
        <div>
            <Modal
                show={showLogin}
            >
                <div className="p-4 text-center">
                    <div className="">
                        <h3 className='text-center'>{t('email_verified')}</h3>
                    </div>
                    <h6 className='text-center'>{t('acc_created_now_login')}.</h6>
                    <p className='text-center'>
                        <span className="material-symbols-outlined"
                            style={{
                                color: 'green',
                                fontSize: '3rem'
                            }}
                        >
                            check_circle
                        </span>
                    </p>
                    <Link to='/' className='btn p-btn'>{t('login')}</Link>
                </div>
            </Modal>
            <Modal
                show={showOTP}
            >
                <div className="p-4">
                    <div className="d-flex jcsb">
                        <h3>{t('verify_otp')}</h3>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                setShowOTP(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                    <h6 className='mt-5'>{t('enter_otp')}</h6>
                    <div className='mt-4 mb-4'>
                        <InputOtp
                            length={6}
                            value={otp}
                            onChange={(e) => setOtp(e.value)}
                            integerOnly
                        />
                    </div>
                    <Button label={t("verify")} loading={loading} className='p-btn' id='register'
                        onClick={(e) => {
                            handleRegisterUser();
                        }}
                    />
                </div>
            </Modal>
            <div className=''>
                <div className="row vh-center">
                    <div className="col-md-8">
                        <div className='glass-card p-3 text-black m-3'>
                            <h3>{t('register_as_stud')}</h3>
                            <form action="" method="post"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (process.env.REACT_APP_BYPASS_OTP === 'true') {
                                        handleRegisterUser();
                                    } else {
                                        triggerOTP();
                                    }
                                }}
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="name" className='required mb-1'>{t('alias_name')}</label>
                                            <input type="text" className='form-control' required
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone" className=' mb-1'>{t('phone')}</label>
                                            <input type="text" className='form-control'
                                                value={phone}
                                                onChange={(e) => {
                                                    setPhone(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email" className='required mb-1'>{t("email")}</label>
                                            <input type="email" className='form-control' required
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                                value={email}
                                                onBlur={() => { checkUserEmail() }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="password" className='required mb-1'>{t("password")}</label>
                                            <Password className='pr-input' toggleMask required
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="country" className='required mb-1'>{t("country")}</label>
                                    <Select options={countries}
                                        placeholder={t('select')}
                                        getOptionLabel={(l) => l.country_name}
                                        getOptionValue={(v) => v.id}
                                        value={country}
                                        onChange={(e) => {
                                            setCountry(e)
                                            setSelectedCollege([]);
                                            loadColleges(e);
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="collge" className='required mb-1'>{t('colleges')}</label>
                                    <Dropdown
                                        className="pr-input"
                                        optionGroupLabel="cc"
                                        optionLabel="name"
                                        optionGroupChildren="colleges"
                                        options={colleges}
                                        filter
                                        placeholder={t('select')}
                                        value={selectedCollege}
                                        onChange={(e) => setSelectedCollege(e.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="major" className='required mb-1'>{t("major")}</label>
                                    <Select options={majors}
                                        value={major}
                                        onChange={(e) => {
                                            setMajor(e)
                                        }}
                                        required
                                        getOptionLabel={(e) => e.name}
                                        getOptionValue={(e) => e.id}
                                        placeholder={t('select')}
                                    />
                                </div>
                                <div className="flex align-items-center mt-3">
                                    <Checkbox
                                        inputId="ingredient1"
                                        name="tc"
                                        value={tc}
                                        onChange={() => {
                                            setTc(!tc);
                                        }}
                                        checked={tc}
                                    ></Checkbox>
                                    <label htmlFor="ingredient1" className="mx-2">
                                        <Link to="/terms-conditions">{t('agree_tc')}</Link>
                                    </label>
                                </div>
                                <div className="form-group mt-3">
                                    <Button label={t("register")} loading={loading} type='submit' className='p-btn' id='register' />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StudentRegister;