import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {sendMailToUser} from '../../apis/services';
const EmailCommunicate = () => {
    const { t } = useTranslation();
    const [subject, setSubject] = useState();
    const [email, setEmail] = useState();
    const [content, setContent] = useState();
    const toastRef = useRef();
    const handleSendMailToUser = async() =>{
        try {
            let data = {
                content:content,
                email:email,
                subject:subject
            };
            await sendMailToUser(data);
            toastRef.current.show({ severity: 'success', summary: t('success'), detail: t('email_sent'), life: 3000 }); 

        } catch (error) {
            
        }
    }
    return (
        <div className="p-3">
            <Toast ref={toastRef}/>
            <form action="" method="post"
                onSubmit={(e)=>{
                    e.preventDefault();
                    handleSendMailToUser();
                }}
            >
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="subject" className='mb-1 required'>{t('subject')}</label>
                            <input type="text" name="subject" id="subject" className='form-control' required
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="email" className='mb-1 required'>{t('email')}</label>
                            <input type="email" name="email" id="email" className='form-control' required
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="body" className='mb-1 required'>{t('body')}</label>
                            <textarea name="body" id="body" className='form-control' rows={7}
                            value={content}
                            onChange={(e)=>{
                                setContent(e.target.value);
                            }}
                            />
                        </div>
                    </div>
                    <div className="col-12 mt-3 mb-3">
                        <Button type='submit' outlined label={t('send_message')} />
                    </div>
                </div>
            </form>
        </div>
    );
}
export default EmailCommunicate;