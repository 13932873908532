import React from 'react';

const InstructorTemplate = () => {
    return (
        <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f4f4', margin: 0, padding: '20px' }}>
            <div style={{  background: '#fff', padding: '40px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', margin: 'auto' }}>
                <div style={{ textAlign: 'center' }}>
                    <img src="https://ta-pal.com/logo.png" alt="TA-PAL Logo" width="75px" style={{ borderRadius: '5px' }} />
                </div>
                <h2 style={{ textAlign: 'center' }}>Invitation for Instructors & Professors</h2>
                <p>We’re excited to invite you to join <strong>TA-PAL</strong>, a new educational platform.</p>
                <p>TA-PAL connects you with students from universities in Kuwait and abroad, giving you the opportunity to support their academic journey.</p>
                <p>As an early member, you will have a unique opportunity to engage with students and shape the platform.</p>
                <ul style={{ paddingRight: '20px' }}>
                    <li>Completely free registration</li>
                    <li>Help students improve their academic skills</li>
                    <li>Provide guidance through a user-friendly platform</li>
                    <li>Opportunity to share your expertise</li>
                </ul>
                <p>Join today and be part of the <strong>TA-PAL</strong> community!</p>
                <div style={{ textAlign: 'center' }}>
                    <a href="https://ta-pal.com" style={{ display: 'inline-block', backgroundColor: '#007bff', color: '#fff', padding: '12px 20px', textDecoration: 'none', borderRadius: '5px', fontSize: '16px', marginTop: '15px' }}>Sign up now</a>
                </div>
                <div style={{ marginTop: '20px', fontSize: '14px', color: '#666', textAlign: 'center' }}>
                    <p>© TA-PAL. All rights reserved.</p>
                    <p>You're receiving this email because we find you as an expert in the field of teaching. If you no longer wish to receive these emails, <a href="https://app.ta-pal.com/unsubscribe">unsubscribe here</a>.</p>
                    <div style={{ margin: '10px' }}>
                        <a href="https://www.instagram.com/t.a.pal"><img src="https://ta-pal.com/assets/img/social/instagram.png" alt="" width="32px" /></a>
                        <a href="https://www.linkedin.com/company/ta-pal-app"><img src="https://ta-pal.com/assets/img/social/linkedin.png" alt="" width="32px" /></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstructorTemplate;
