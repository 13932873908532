import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStudentProfile } from './../../apis/services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from "primereact/inputswitch";
import { FilterMatchMode } from 'primereact/api';
import { suspendUser, activateUser } from '../../apis/services';
import { Button } from 'primereact/button';


const ViewStudent = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const inst = JSON.parse(location.state);
    const [studentProfile, setStudentProfile] = useState();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(() => {
        loadInstructorProfile();
    }, []);
    const loadInstructorProfile = async () => {
        try {
            let uid = inst.id;
            const res = await getStudentProfile(uid);
            setStudentProfile(res.data);
        } catch (error) {
            console.log(error);

        }
    }
    const handleSuspendUser = async () => {
        let data = {
            id: inst.id,
        }
        await suspendUser(data);
        navigate('/students')
    }
    const handleActivevateUser = async () => {
        let data = {
            id: inst.id,
        }
        await activateUser(data);
        navigate('/students')
    }
    return (
        <div className="">
            <div className="row">
                <div className="col-md-6">
                    <div className="glass-card p-3">
                        <ul className="well-data-list mt-4">
                            <li className="d-flex jscb mb-2">
                                <span>{t('alias_name')}</span>
                                <strong>{inst.alias_name}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('code')}</span>
                                <strong>{inst.code}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('email')}</span>
                                <strong>{inst.email}</strong>
                            </li>
                            <li className="d-flex jscb  mb-2">
                                <span>{t('phone')}</span>
                                <strong>{inst.phone}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 mt-3 mt-lg-1">
                    <div className="glass-card p-3">
                        <h4>{t('wallet')}</h4>
                        {
                            studentProfile !== undefined &&
                            <h3>KWD {studentProfile.wallet.current_balance.toFixed(3)}</h3>
                        }
                        <div className='p-2 mb-2 mt-4 mb-4'>
                            {/* <Link to="m-1" className='link-btn'>Payout Requests</Link> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    {
                        inst.active === 1 &&
                        <Button label={t('suspend_user')} className='p-btn'
                            onClick={() => {
                                handleSuspendUser();
                            }}
                        />
                    }
                    {
                        inst.active === 0 &&
                        <Button label={t('activate_user')} className='p-btn'
                            onClick={() => {
                                handleActivevateUser();
                            }}
                        />
                    }
                </div>
                <div className="col-md-12 mt-3">
                    <div className="glass-card p-3">
                        <h4>{t('help_requests')}</h4>
                        <div>
                            <DataTable
                                value={studentProfile?.homeWorks}
                                filters={filters}
                                paginator
                                rows={10}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[10, 25, 50]}
                                dataKey="id"
                                selectionMode="checkbox"
                                filterDisplay="menu"
                                emptyMessage={t('no_data')}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                header={
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <input className='form-control'
                                                placeholder={t('search')}
                                                onChange={(e) => {
                                                    let _filters = { ...filters };
                                                    _filters['global'].value = e.target.value;
                                                    setFilters(_filters);
                                                }}
                                            />
                                        </div>

                                    </div>
                                }
                            >

                                <Column
                                    field="id"
                                    header={t('hr_id')}
                                    sortable
                                    body={(r) => {
                                        return (
                                            <span>HR_{r.id}</span>
                                        );
                                    }}
                                />
                                <Column
                                    field="hw_title"
                                    header={t('subject')}
                                    sortable

                                />
                                <Column
                                    field="hw_type"
                                    header={t('type')}
                                    sortable
                                />
                                <Column
                                    header={t('urgent')}
                                    sortField='urgent'
                                    sortable
                                    body={(row) => {
                                        return (
                                            <InputSwitch checked={row.urgent === 1 ? true : false} />
                                        )
                                    }}
                                />

                                {/* <Column
                                    header={"Status"}
                                    body={(r) => <span><strong>{r.approved === 0 ? "Under Review" : r.answered === 0 ? 'Quality Check Passed' : 'Answered'}</strong></span>}
                                /> */}
                                <Column
                                    field="due_date"
                                    header={t('due_date')}
                                    sortable
                                />

                                <Column
                                    header={t('actions')}
                                    body={(row) => {
                                        return (
                                            <div className='d-flex'>
                                                <Link
                                                    to="/assignments/view"
                                                    state={row.id}
                                                    className='link-icon-btn mx-1' severity='info' id="edit-btn">
                                                    <span className="material-symbols-outlined">
                                                        visibility
                                                    </span>
                                                </Link>
                                            </div>
                                        )
                                    }}
                                />
                            </DataTable>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}
export default ViewStudent;