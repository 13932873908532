import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { sendInviationForUsers } from '../../apis/services';
import Select from 'react-select';
import { Toast } from 'primereact/toast';
import StudentTemplate from './templates/StudentTemplate';
import InstructorTemplate from './templates/InstructorTemplate';
const EmailInvite = () => {
    const { t } = useTranslation();
    const toastRef = useRef();

    const [emailSheet, setEmailSheet] = useState();
    const [inviteFor, setInviteFor] = useState({});
    const inviteFors = [
        {
            value: 'inst',
            label: t('instructors')
        },
        {
            value: 'stud',
            label: t('students')
        },
    ];
    const handleUsersInvitation = async () => {
        try {
            const formData = new FormData();
            formData.append('inviteFor', inviteFor.value)
            formData.append('emails_sheet', emailSheet)
            await sendInviationForUsers(formData);
            toastRef.current.show({ severity: 'success', summary: t('success'), detail: t('invites_added_to'), life: 3000 });
        } catch (error) {
            console.log(error);

        }
    }
    return (
        <div className="p-2">
            <Toast ref={toastRef} />
            <form action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    handleUsersInvitation();
                }}
            >
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label htmlFor="invite_for" className='mb-1 required'>{t('invite_for')}</label>
                            <Select
                                options={inviteFors}
                                required
                                placeholder={t('select')}
                                value={inviteFor}
                                onChange={(e) => {
                                    setInviteFor(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="form-group mt-2">
                            <label htmlFor="file" className='mb-1 required'>{t('emails_sheet')}</label>
                            <input type='file' className='form-control' accept='.xlsx' name='emails_sheet'
                                onChange={(e) => {
                                    setEmailSheet(e.target.files[0]);
                                }}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="form-group mt-2">
                            <Button className='mt-3' label={t('send_invite')} type='submit' outlined />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        {
                            inviteFor.value !== undefined &&
                            <div className="mb-2 mt-3">
                                {
                                    inviteFor.value === "inst" &&
                                    <InstructorTemplate />
                                }
                                {
                                    inviteFor.value === "stud" &&
                                    <StudentTemplate />
                                }
                            </div>
                        }
                    </div>

                </div>
            </form>
        </div>
    );
}

export default EmailInvite;