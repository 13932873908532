import React, { useState, useEffect, useRef } from 'react';
import { updateInstProfile, updateUserPassword, getRegisterData, getCollegesByCountries,saveInstructorSettings } from './../../apis/services';
import { Button } from 'primereact/button';
import store from '../../redux/store';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from "primereact/inputswitch";
const InstProfile = () => {
    const toastRef = useRef();
    const { t } = useTranslation();
    const userStore = store.getState().app.user;
    const user = userStore.user;
    const userSettings = userStore.more;
    const [aname, setAname] = useState(user.alias_name);
    const [phone, setPhone] = useState(user.phone);
    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [selectedCollege, setSelectedCollege] = useState([]);
    const [colleges, setColleges] = useState([]);
    const [country, setCountry] = useState();
    const [countries, setCountries] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [optInForTutoring, setOptInForTutoring] = useState(userSettings.opt_in_for_tutoring);
    const [optInForOnlineTutoring, setOptInForOnlineTutoring] = useState(userSettings.opt_in_for_online_tutoring);
    const [optInForInHouseTutoring, setOptInForInHouseTutoring] = useState(userSettings.opt_in_for_in_house_tutoring);

    useEffect(() => {
        loadGetRegisterData();
    }, []);
    const loadGetRegisterData = async () => {
        try {
            const res = await getRegisterData();
            setCountries(res.data.countries);
            const existCountry = res.data.countries.find((c) => user.country.split(',').includes(c.id.toString()))
            setCountry(existCountry);
            loadColleges(existCountry);
            setSubjects(res.data.subjects);
            let existingSubs = res.data.subjects.find((c) => c.subjects.find((cl) => JSON.parse(userStore.more.subjects).includes(cl.id)));
            setSelectedSubjects(existingSubs.subjects.filter((cl) => JSON.parse(userStore.more.subjects).includes(cl.id)));
        } catch (error) {

        }
    }
    const loadColleges = async (list) => {
        try {
            let data = {
                cids: [list.id],
            }
            const res = await getCollegesByCountries(data);
            setColleges(res.data.colleges);
            let existingClgs = res.data.colleges.find((c) => c.colleges.find((cl) => JSON.parse(userStore.more.colleges).includes(cl.id)));
            setSelectedCollege(existingClgs.colleges.filter((cl) => JSON.parse(userStore.more.colleges).includes(cl.id)));
        } catch (error) {

        }
    }
    const handleUpdateUser = async () => {
        try {
            let data = {
                aname: aname,
                phone: phone,
                country: country.id,
                colleges: JSON.stringify(selectedCollege.map((c) => c.id)),
                subjects: JSON.stringify(selectedSubjects.map((s) => s.id)),
            }
            await updateInstProfile(data);
            window.location.reload();
        } catch (error) {

        }
    }
    const handleSaveInstructorSettings = async () =>{
        try {
            let data = {
                optInForTutoring:optInForTutoring,
                optInForOnlineTutoring:optInForOnlineTutoring,
                optInForInHouseTutoring:optInForInHouseTutoring,
            }
            await saveInstructorSettings(data);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    }
    const handleUpdateUserPassword = async () => {
        try {
            if (newPassword !== confirmPassword) {
                toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('passwords_not_matching'), life: 3000 });
            } else {
                let data = {
                    newPassword: newPassword,
                    currentPassword: currentPassword
                }
                const res = await updateUserPassword(data);
                if (res.data.status) {
                    toastRef.current.show({ severity: 'success', summary: t('success'), detail: t('password_changed'), life: 3000 });
                } else {
                    toastRef.current.show({ severity: 'error', summary: t('error'), detail: t('password_change_error'), life: 3000 });
                }

            }

        } catch (error) {

        }
    }
    return (
        <div className="p-3">
            <Toast ref={toastRef} />
            <div className="row">
                <div className="col-md-8 mb-3">
                    <div className="glass-card p-3">
                        <h5>{t('inst_profile')}</h5>
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateUser();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="alias_name" className='required mb-1'>{t('alias_name')}</label>
                                <input type="text" name="alias_name" id="alias_name" required className='form-control'
                                    value={aname}
                                    onChange={(e) => {
                                        setAname(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="code" className='required mb-1'>{t('code')}</label>
                                <input type="text" name="code" id="code" required className='form-control' disabled readOnly value={user.code} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" className='required mb-1'>{t('email')}</label>
                                <input type="email" name="email" id="email" required className='form-control' disabled readOnly value={user.email} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone" className='required mb-1'>{t('phone')}</label>
                                <input type="text" name="phone" id="phone" required className='form-control'
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="country" className='mb-1'>{t('country')}</label>
                                <Select options={countries}
                                    getOptionLabel={(l) => l.country_name}
                                    getOptionValue={(v) => v.id}
                                    value={country}
                                    placeholder={t('country')}
                                    onChange={(e) => {
                                        setCountry(e)
                                        setSelectedCollege([]);
                                        loadColleges(e);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="collge" className='mb-1 required'>{t('college')}</label>
                                <MultiSelect
                                    value={selectedCollege}
                                    onChange={(e) => setSelectedCollege(e.value)}
                                    options={colleges}
                                    optionLabel="name"
                                    optionGroupLabel="cc"
                                    optionGroupChildren="colleges"
                                    className="pr-input"
                                    placeholder={t('select_colleges')}
                                    display='chip'
                                    filter
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="major" className='required mb-1'>{t('subjects')}</label>
                                <MultiSelect
                                    value={selectedSubjects}
                                    onChange={(e) => setSelectedSubjects(e.value)}
                                    options={subjects}
                                    optionLabel="name"
                                    optionGroupLabel="sc"
                                    optionGroupChildren="subjects"
                                    className="pr-input"
                                    placeholder={t('select_subjects')}
                                    display='chip'
                                    filter
                                    required
                                />
                            </div>
                            <div className="form-group mt-4">
                                <Button label={t('update_profile')} />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="glass-card p-3 mb-3">
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUpdateUserPassword();
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="currentPassword" className='required mb-1'>{t('current_password')}</label>
                                <input type="password" name="currentPassword" id="currentPassword" required className='form-control'
                                    value={currentPassword}
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword" className='required mb-1'>{t('new_password')}</label>
                                <input type="password" name="newPassword" id="newPassword" required className='form-control'
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className='required mb-1'>{t('confirm_password')}</label>
                                <input type="password" name="confirmPassword" id="confirmPassword" required className='form-control'
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form-group mt-4">
                                <Button label={t('update_password')} />
                            </div>
                        </form>
                    </div>
                    <div className="glass-card p-3 mb-3">
                        <h5 className='mb-2'>{t('settings')}</h5>
                        <div className="d-flex jcsb align-items-center mb-2">
                            <p>{t('opt_in_for_tutoring')}</p>
                            <InputSwitch
                                checked={optInForTutoring === 1}
                                onChange={() => {
                                    setOptInForTutoring(optInForTutoring === 1 ? 0 : 1);
                                }}
                            />
                        </div>
                        <div className="d-flex jcsb align-items-center mb-2">
                            <p>{t('opt_in_for_online_tutoring')}</p>
                            <InputSwitch
                                checked={optInForOnlineTutoring === 1}
                                onChange={() => {
                                    setOptInForOnlineTutoring(optInForOnlineTutoring === 1 ? 0 : 1);
                                }}
                            />
                        </div>
                        <div className="d-flex jcsb align-items-center mb-2">
                            <p>{t('opt_in_for_in_house_tutoring')}</p>
                            <InputSwitch
                                checked={optInForInHouseTutoring === 1}
                                onChange={() => {
                                    setOptInForInHouseTutoring(optInForInHouseTutoring === 1 ? 0 : 1);
                                }}
                            />
                        </div>
                        <Button label={t('save_settings')} 
                            onClick={()=>{
                                handleSaveInstructorSettings();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default InstProfile;