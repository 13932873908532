import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useStore, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Modal } from 'react-bootstrap';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { addLocale } from 'primereact/api';
import { setErrorToast } from '../redux/reducer';
import Home from '../modules/home/Home';
import Header from '../modules/header/Header';
import Sidebar from '../modules/home/Sidemenu';
import Login from './../modules/auth/Login';
import Splash from './../modules/splash/Splash';
import Logout from './../modules/auth/Logout';
import Page403 from './Page403';
import Page404 from './Page404';
import Users from './../modules/users/Users';
import Subjects from './../modules/subjects/Subjects';
import SubjectCategories from './../modules/subjects/categories/SubjectCategories';
import Majors from './../modules/majors/Majors';
import Colleges from './../modules/colleges/Colleges';
import Notifications from './../modules/notifications/Notifications';
import QAPendingApprovals from './../modules/assignments/QAPendingApprovals';
import InsAvailableRequets from '../modules/assignments/InsAvailableRequets';
import ViewAssignment from './../modules/assignments/ViewAssignment';
import AppLables from './../modules/app_lables/AppLables';
import InsRegister from '../modules/register/InsRegister';
import TermsConditions from '../modules/register/TermsConditions';
import Instructors from '../modules/instructors/Instructors';
import Students from '../modules/students/Students';
import InviteInstructors from '../modules/instructors/InviteInstructors';
import CollegeCountries from '../modules/colleges/CollegeCountries';
import CollegeCategories from '../modules/colleges/CollegeCategories';
import ViewInstructor from '../modules/instructors/ViewInstructor';
import INSQuotes from '../modules/quotes/InsQuotes';
import ViewStudent from '../modules/students/ViewStudent';
import InsPayoutRequests from '../modules/payoutRequests/InsPayoutRequests';
import ArchivedHRs from '../modules/assignments/tabs/qa/ArchivedHRs';
import AllQuotes from '../modules/quotes/AllQuotes';
import AddHRAnswer from '../modules/assignments/answers/AddHRAnswer';
import StudentPofile from '../modules/students/StudentPofile';
import StudentAssignments from '../modules/assignments/StudentAssignments';
import AddAssignment from '../modules/assignments/tabs/stud/AddAssignment';
import StudentQuotes from '../modules/quotes/StudentQuotes';
import StudentWallet from '../modules/wallet/StudentWallet';
import RechargeWallet from '../modules/wallet/RechargeWallet';
import RechargeWalletStatus from '../modules/wallet/RechargeWalletStatus';
import StudentRegister from '../modules/register/StudentRegister';
import QAProfile from '../modules/home/QAProfile';
import InstProfile from '../modules/instructors/InstProfile';
import INSAcceptedQuotes from '../modules/instructors/InsAcceptedQuotes';
import ViewHRAnswers from '../modules/assignments/answers/ViewHRAnswers';
import Studentpayouts from '../modules/payoutRequests/StudentPayouts';
import EditAssignment from '../modules/assignments/tabs/stud/EditAssignment';
import InstructorWallet from '../modules/wallet/InstructorWallet';
import ManageSupportTickets from '../modules/supportTickets/ManageSupportTickets';
import CreateSupportTicket from '../modules/supportTickets/CreateSupportTicket';
import EditSupportTicket from '../modules/supportTickets/EditSupportTicket';
import ViewSupportTicket from '../modules/supportTickets/ViewSupportTicket';
import RequestPayout from '../modules/payoutRequests/RequestPayout';
import AllPayouts from '../modules/payoutRequests/AllPayouts';
import AllSupportTickets from '../modules/supportTickets/AllSupportTickets';
import QAAssignments from '../modules/assignments/QAAssignments';
import InsActiveRequests from '../modules/assignments/InsActiveRequests';
import InsCompletedRequets from '../modules/assignments/InsCompletedRequets';
import InstructorsListing from '../modules/instructors/InstructorsListing';
import FargotPassword from '../modules/auth/FargotPassword';
import ResubmitAnswers from '../modules/assignments/answers/ResubmitAnswers';
import Communicate from '../modules/communicate/Communicate';
const StartOnlineTutoring = React.lazy(() => import('../modules/tutoring/StartOnlineTutoring'));
const JoinOnlineTuition = React.lazy(() => import('../modules/tutoring/JoinOnlineTuition'));
const InstructorTutoringCourses = React.lazy(()=> import('../modules/tutoring/InstructorTutoringCourses'));
const CreateTutoringCourse = React.lazy(()=> import('../modules/tutoring/CreateTutoringCourse'));
const TutoringTimeSlots = React.lazy(()=> import('../modules/tutoring/TutoringTimeSlots')); 

const AppRouter = () => {
    const store = useStore();
    const { t } = useTranslation();
    addLocale('en', {
        clear: t('clear'),
        apply: t('apply')
    });
    const [isUserLogged, setIsUserLogged] = useState(false);
    const [showReqDialog, setShowReqDialog] = useState(false);
    const sideMenuSize = useSelector((state) => state.app.showSidemenu);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    store.subscribe(() => {
        setShowReqDialog(store.getState().app.showDialog);
        if (store.getState().app.showErrorToast.flag) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: store.getState().app.showErrorToast.content, life: 3000 });
            store.dispatch(setErrorToast({
                flag: false,
                content: '',
            }));
        }
        if (store.getState().app.isuserlogged) {
            setIsUserLogged(true);
        } else {
            setIsUserLogged(false);
        }
        setLoading(false);
    });
    if (loading) {
        return (
            <BrowserRouter>
                <Toast ref={toast} />
                <Routes>
                    <Route index element={<Splash />} />

                    {/* WEB RTC TEST ROUTES */}
                    <Route path='/tutoring/start' element={<StartOnlineTutoring />} />
                    <Route path='/tutoring/join' element={<JoinOnlineTuition />} />


                    <Route path='*' element={<Splash />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else if (!isUserLogged && !loading) {
        return (
            <BrowserRouter>
                <Toast ref={toast} />
                <Routes>
                    <Route index element={<Login />} />
                    <Route path='/regsiter/instructor' element={<InsRegister />} />
                    <Route path='/regsiter/student' element={<StudentRegister />} />
                    <Route path='/forgot/password' element={<FargotPassword />} />
                    <Route path='/terms-conditions' element={<TermsConditions />} />

                    <Route path='*' element={<Page403 />} />
                </Routes>
            </BrowserRouter>
        );
    }
    else if (isUserLogged && !loading) {
        return (
            <BrowserRouter>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-lg-2 col-md-3 d-none d-lg-block no_mp p-0 m-0"
                            style={{
                                width: sideMenuSize ? '' : '4.3%',
                            }}>
                            <div className='side-menu fh glass-card-no-radius'>
                                <Sidebar />
                            </div>
                        </div>
                        <div className="col-lg-10 col-sm-12 col-md-12 app-content no_mp m-0 p-0"
                            style={{
                                width: sideMenuSize ? '' : '95.7%',
                            }}
                        >
                            <Header />
                            <div className="content"
                                style={{
                                    minHeight: '80vh',
                                    marginTop: 90,
                                    padding: '1rem'
                                }}>
                                <Toast ref={toast} />
                                <Modal
                                    size="sm"
                                    show={showReqDialog}
                                    centered
                                    className='loading'
                                >
                                    <ProgressSpinner
                                        style={{ width: '50px', height: '50px' }}
                                        strokeWidth="3"
                                        animationDuration=".5s"
                                    />
                                </Modal>
                                <Routes>
                                    <Route index path='/' element={<Home />} />
                                    <Route path='/users' element={<Users />} />
                                    <Route path='/subjects' element={<Subjects />} />
                                    <Route path='/subjects/categories' element={<SubjectCategories />} />
                                    <Route path='/majors' element={<Majors />} />
                                    <Route path='/colleges' element={<Colleges />} />
                                    <Route path='/colleges/countries' element={<CollegeCountries />} />
                                    <Route path='/colleges/categories' element={<CollegeCategories />} />
                                    <Route path='/pending-approvals' element={<QAPendingApprovals />} />
                                    <Route path='/assignments' element={<QAAssignments />} />
                                    <Route path='/ins/assignments-available' element={<InsAvailableRequets />} />
                                    <Route path='/ins/assignments-active' element={<InsActiveRequests />} />
                                    <Route path='/ins/assignments-completed' element={<InsCompletedRequets />} />
                                    <Route path='/assignments/view' element={<ViewAssignment />} />
                                    <Route path='/notifications' element={<Notifications />} />
                                    <Route path='/app-lables' element={<AppLables />} />
                                    <Route path='/instructors' element={<Instructors />} />
                                    <Route path='/instructors/all' element={<InstructorsListing />} />
                                    <Route path='/invite/instructors' element={<InviteInstructors />} />
                                    <Route path='/instructors/view' element={<ViewInstructor />} />
                                    <Route path='/ins/quotes' element={<INSQuotes />} />
                                    <Route path='/ins/accepted-requests' element={<INSAcceptedQuotes />} />
                                    <Route path='/students' element={<Students />} />
                                    <Route path='/students/view' element={<ViewStudent />} />
                                    <Route path='/ins/payout-requests' element={<InsPayoutRequests />} />
                                    <Route path='/ins/payout-requests/create' element={<RequestPayout />} />
                                    <Route path='/payout-requests' element={<AllPayouts />} />
                                    <Route path='/archived' element={<ArchivedHRs />} />
                                    <Route path='/hrs-quotes' element={<AllQuotes />} />
                                    <Route path='/assignments/add-answers' element={<AddHRAnswer />} />
                                    <Route path='/student/profile' element={<StudentPofile />} />
                                    <Route path='/student/assignments' element={<StudentAssignments />} />
                                    <Route path='/student/add/assignments' element={<AddAssignment />} />
                                    <Route path='/student/quotes' element={<StudentQuotes />} />
                                    <Route path='/student/wallet' element={<StudentWallet />} />
                                    <Route path='/wallet/recharge' element={<RechargeWallet />} />
                                    <Route path='/wallet/recharge/status' element={<RechargeWalletStatus />} />
                                    <Route path='/qa/profile' element={<QAProfile />} />
                                    <Route path='/teacher/profile' element={<InstProfile />} />
                                    <Route path='/assignments/answers/view' element={<ViewHRAnswers />} />
                                    <Route path='/student/payouts' element={<Studentpayouts />} />
                                    <Route path='/assignments/edit' element={<EditAssignment />} />
                                    <Route path='/instructor/wallet' element={<InstructorWallet />} />
                                    <Route path='/support-tickets' element={<ManageSupportTickets />} />
                                    <Route path='/support-tickets/create' element={<CreateSupportTicket />} />
                                    <Route path='/support-tickets/edit' element={<EditSupportTicket />} />
                                    <Route path='/support-tickets/view' element={<ViewSupportTicket />} />
                                    <Route path='/support-tickets/all' element={<AllSupportTickets />} />
                                    <Route path='/assignments/answers/resubmit' element={<ResubmitAnswers />} />
                                    <Route path='/communicate' element={<Communicate />} />
                                    <Route path='/ins/tutoring' element={<InstructorTutoringCourses />} />
                                    <Route path='/ins/tutoring/create' element={<CreateTutoringCourse />} />
                                    <Route path='/ins/tutoring/slots' element={<TutoringTimeSlots />} />

                                    <Route path='/tutoring/start' element={<StartOnlineTutoring />} />
                                    <Route path='/tutoring/join' element={<JoinOnlineTuition />} />
                                    <Route path='/logout' element={<Logout />} />
                                    <Route path='*' element={<Page404 />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        )
    }
}
export default AppRouter;