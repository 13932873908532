import React from "react";
const StudentTemplate = () => {
    return (
        <div style={styles.body}>
            <div style={styles.emailContainer}>
                <div style={styles.header}>
                    <img src="https://ta-pal.com/logo.png" alt="TA-PAL Logo" width="75px" style={styles.logo} />
                </div>
                <h2 style={styles.textCenter}>Invitation for College & University Students</h2>
                <p>TA-PAL, the Leading Educational Platform for University and PAAET Students in Kuwait.</p>
                <p>Are you a university or PAAET student in Kuwait looking for expert academic assistance?</p>
                <p>
                    Welcome to <strong>TA-PAL</strong>, the revolutionary platform that connects students with experienced
                    instructors, professors, and academic mentors to enhance their learning experience.
                </p>
                <ul>
                    <li>Access to a network of highly qualified educators.</li>
                    <li>Personalized Learning.</li>
                    <li>Support for Public & Private Universities.</li>
                    <li>Flexible and Convenient.</li>
                    <li>Scholarship Student Support.</li>
                </ul>
                <p>Join today and be part of the <strong>TA-PAL</strong> community!</p>
                <div style={styles.textCenter}>
                    <a href="https://ta-pal.com" style={styles.ctaButton}>Join now</a>
                </div>
                <hr />
                <h2 style={styles.textCenter}>دعوة لطلبة الكليات والجامعات</h2>
                <div style={styles.rightText}>
                    <p>لطلاب الجامعات والتطبيقي بالكويت – تبي تشد حيلك بالدراسة وما توقف، الحل عند تي أي بال</p>
                    <p>تحس ان بعض المواد صعبة؟ عندك واجبات مو فاهمها؟ تي أي بال يساعدك توصل للمعلومة بطريقة واضحة وسهلة، من
                        أساتذة فاهمين ومتمكنين في كليتك.</p>
                    <ul style={{ direction: "rtl" }}>
                        <li>الوصول إلى أفضل الأساتذة – احصل على دعم أكاديمي مباشر من أساتذة جامعيين ومحاضرين معتمدين.</li>
                        <li>تعلم شخصي مخصص – احصل على إرشاد أكاديمي يتناسب مع موادك الدراسية</li>
                        <li>دعم لجميع الجامعات والمؤسسات التعليمية – سواء كنت في جامعة الكويت، أو AUM، أو GUST، أو AUK، أو PAAET، نحن هنا لمساعدتك.</li>
                        <li>دعم الطلاب المبتعثين – TA-PAL يساعد أيضًا الطلاب الكويتيين الدارسين في الخارج ضمن برامج الابتعاث الحكومي.</li>
                        <li>مرونة وسهولة الوصول – احصل على المساعدة الأكاديمية في أي وقت ومن أي مكان.</li>
                        <li>خصوصية تامة بدون أي إحراج</li>
                    </ul>
                    <p>خلك ذكى، وسهّل على نفسك الأمور – جرب تي أي بال الحين</p>
                    <div style={styles.textCenter}>
                        <a href="https://ta-pal.com/?lang=ar" style={styles.ctaButton}>سجل مجانًا اليوم</a>
                    </div>
                </div>
                <div style={styles.footer}>
                    <p>© TA-PAL. All rights reserved.</p>
                    <p>
                        You're receiving this email because we find you as an expert in the field of teaching.
                        If you no longer wish to receive these emails,
                        <a href="https://app.ta-pal.com/unsubscribe">unsubscribe here</a>.
                    </p>
                    <div style={styles.socialLinks}>
                        <a href="https://www.instagram.com/t.a.pal">
                            <img src="https://ta-pal.com/assets/img/social/instagram.png" alt="Instagram" width="32px" />
                        </a>
                        <a href="https://www.linkedin.com/company/ta-pal-app">
                            <img src="https://ta-pal.com/assets/img/social/linkedin.png" alt="LinkedIn" width="32px" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    body: {
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f4f4f4",
        margin: 0,
        padding: "20px",
    },
    emailContainer: {
        background: "#fff",
        padding: "40px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        margin: "auto",
    },
    ctaButton: {
        display: "inline-block",
        backgroundColor: "#007bff",
        color: "#fff",
        padding: "12px 20px",
        textDecoration: "none",
        borderRadius: "5px",
        fontSize: "16px",
        marginTop: "15px",
    },
    textCenter: {
        textAlign: "center",
    },
    rightText: {
        textAlign: "right",
    },
    header: {
        textAlign: "center",
    },
    footer: {
        marginTop: "20px",
        fontSize: "14px",
        color: "#666",
        textAlign: "center",
    },
    socialLinks: {
        margin: "10px",
    },
    logo:{
        borderRadius:"5px"
    }
};

export default StudentTemplate;
