import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useSelector, useStore } from 'react-redux';
import { setShowSidemenu } from '../../redux/reducer';
import QASidemenu from './sidemenu/QASidemenu';
import StudentMenu from './sidemenu/StudentMenu';
import InstMenu from './sidemenu/InstMenu';
const Sidebar = (props) => {
    const store = useStore();
    const user = store.getState().app.user.user;
    const { t } = useTranslation();
    const location = useLocation();
    const [activePath, setActivePath] = useState('/');
    const [accordionActiveKey, setAccordionActiveKey] = useState('null');
    const sizeToggler = useSelector((state) => state.app.showSidemenu);
    useEffect(() => {
        const path = location.pathname;
        setActivePath(path);
        if (!sizeToggler) {
            setAccordionActiveKey(null);
        }
    }, [location.pathname, sizeToggler]);
    const handleAccordionToggle = (eventKey) => {
        if (sizeToggler) {
            setAccordionActiveKey(accordionActiveKey === eventKey ? null : eventKey);
        }
    };

    return (
        <div className={`app-sidemenu-bar ${sizeToggler ? 'open' : 'closed'}`}>
            {sizeToggler &&
                <div className="text-white m-2 mt-4">
                    <h6>{t('hello')} {t(user.role)}</h6>
                    <hr />
                </div>
            }
            {
                !sizeToggler &&
                <div className='p-1 mt-4 mb-3'>
                    <img src="/logo.png" className='logo'
                        style={{
                            width: '100%',
                        }}
                    />
                </div>
            }
            <li className={`app-sidemenu-bar-item ${activePath === '/' ? 'active' : ''}`}>
                <Link to="/">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            space_dashboard
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('home')}
                        </span>

                    </span>
                </Link>
            </li>
            {
                user.role === 'qa' &&
                <QASidemenu />
            }
            {
                user.role === 'teacher' &&
                <InstMenu/>
               
            }
            {
                user.role === 'student' &&
               <StudentMenu/>
            }
            <li className={`app-sidemenu-bar-item ${activePath.includes('notifications') ? 'active' : ''}`}>
                <Link to="/notifications">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            notifications
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('notifications')}
                        </span>
                    </span>
                </Link>
            </li>
            <div className="mt-3">
                <Button
                    className='icon-btn' severity='secondary'
                    style={{
                        marginLeft: 8,
                        marginRight: 12,
                    }}
                    onClick={() => {
                        const check = !sizeToggler;
                        store.dispatch(setShowSidemenu(check));
                    }}
                >
                    {sizeToggler ? (

                        <span className="material-symbols-outlined">
                            first_page
                        </span>
                    ) : (
                        <span className="material-symbols-outlined">
                            last_page
                        </span>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default Sidebar;
