import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button } from 'primereact/button';
import { sendWhatsappInvite } from '../../apis/services';
import { Toast } from 'primereact/toast';
const WhatsAppInvite = () => {
    const { t } = useTranslation();

    const [inviteFor, setInviteFor] = useState({});
    const [phoneNumbersSheet, setPhoneNumbersSheet] = useState();
    const toastRef = useRef();
    const inviteFors = [
        {
            value: 'HX9820e40e38f128b7745dee49e7f36d6a',
            label: t('instructor_en')
        },
        {
            value: 'HX0ba027ac491f7bd951f3709bf00d7e50',
            label: t('students_en')
        },
        {
            value: 'HXd02425353b589296a656a5118ab578fa',
            label: t('students_ar')
        }
    ];
    const handleInvitation = async () => {
        try {
            const formData = new FormData();
            formData.append('inviteFor', inviteFor.value);
            formData.append('phoneNumbersSheet', phoneNumbersSheet);
            await sendWhatsappInvite(formData);
            toastRef.current.show({ severity: 'success', summary: t('success'), detail: t('invites_added_to'), life: 3000 });
        } catch (error) {

        }
    }
    return (
        <div className="p-2">
            <Toast ref={toastRef} />
            <form action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    handleInvitation();
                }}
            >
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="form-group">
                            <label htmlFor="invite_for" className='mb-1 required'>{t('invite_for')}</label>
                            <Select
                                options={inviteFors}
                                required
                                placeholder={t('select')}
                                value={inviteFor}
                                onChange={(e) => {
                                    setInviteFor(e);
                                }}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <label htmlFor="file" className='mb-1 required'>{t('phone_numbers_sheet')}</label>
                            <input type='file' className='form-control' accept='.xlsx' name='phone_numbers_sheet'
                                onChange={(e) => {
                                    setPhoneNumbersSheet(e.target.files[0]);
                                }}
                                required
                            />
                            <div className="form-group mt-3">
                                <Button className='mt-3' label={t('send_invite')} type='submit' outlined />
                            </div>
                        </div>
                    </div>

                    {
                        inviteFor.value !== undefined &&
                        <div className="col-md-6 mb-2 mt-3 text-center">
                            <img src={"/assets/img/" + inviteFor.value + ".png"} alt="" className='glass-card p-2'
                                style={{
                                    height: '35rem',
                                    borderRadius: 40
                                }}
                            />
                        </div>}
                </div>
            </form>
        </div>
    );
}

export default WhatsAppInvite;