import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import EmailCommunicate from './EmailCommunicate';
import WhatsAppInvite from './WhatsAppInvite';
import EmailInvite from './EmailInvite';
const Communicate = () => {
    const { t } = useTranslation();
    return (
        <div className="glass-card p-3">
            <TabView>
                <TabPanel header={t('send_message')} leftIcon={<img src='/assets/img/message.png' width={32} className='mx-2' />}>
                    <EmailCommunicate/>
                </TabPanel>
                <TabPanel header={t('send_whatsapp')} leftIcon={<img src='/assets/img/whatsapp.png' width={32} className='mx-2' />}>
                    <WhatsAppInvite/>
                </TabPanel>
                <TabPanel header={t('send_mail_invite')} leftIcon={<img src='/assets/img/email.png' width={32} className='mx-2' />}>
                    <EmailInvite/>
                </TabPanel>
            </TabView>
        </div>
    );
}
export default Communicate;