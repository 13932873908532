import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getStudents, sendBulkEmails, getStudentData } from '../../apis/services';
import { InputSwitch } from "primereact/inputswitch";
import { FilterMatchMode } from 'primereact/api';
import Select from 'react-select';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';

const Students = () => {
    const { t } = useTranslation();
    const toast = useRef();
    const [students, setStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [action, setAction] = useState();
    const [showMailEditor, setShowMailEditor] = useState(false);
    const [mailContent, setMailContent] = useState('TA-PAL');
    const [mailSubject, setMailSubject] = useState('TA-PAL');
    const [showStudentData, setShowStudentData] = useState({ show: false, item: {} });
    let actionOptions = [
        {
            value: 'send_mail',
            label: t('send_mail')
        },
        // {
        //     value: '',
        //     label: t('delete_selected')
        // }
    ];
    useEffect(() => {
        loadStudents();
    }, []);
    const loadStudents = async () => {
        try {
            const res = await getStudents();
            setStudents(res.data.students);
        } catch (error) {

        }
    }
    const handleInstructorsBulkAction = () => {
        setShowMailEditor(true);

    }
    const handleShowStudMoreData = async (stud) => {
        try {
            console.log(stud);

            const res = await getStudentData({ id: stud });
            setShowStudentData({ show: true, item: res.data.student });
        } catch (error) {

        }
    }

    const handleSendBulkEmail = async () => {
        try {
            let mails = selectedStudents.map((e) => e.email);
            let data = {
                emails: mails,
                mailContent: mailContent,
                mailSubject: mailSubject
            }
            await sendBulkEmails(data);
            setShowMailEditor(false);
            toast.current.show({ severity: 'success', summary: t('success'), detail: t('emails_added_broadcast_list'), life: 3000 });

        } catch (error) {
            console.log(error);

        }
    }
    return (
        <div className="p-3 glass-card">
            <Toast ref={toast} />
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('students')}</h4>
                </div>
                <div className='p-2'>
                    {/* <Link to="" className='link-btn'>Invite Instructors</Link> */}
                </div>
            </div>
            <Modal
                show={showStudentData.show}
            >
                <div className="p-4">
                    <h5>{t('college')} - <strong>{showStudentData.item.college}</strong></h5>
                    <h5>{t('major')} - <strong>{showStudentData.item.major}</strong></h5>
                    <Button outlined label={t('close')} className='mt-4'
                        onClick={() => {
                            setShowStudentData({ show: false, item: {} })
                        }}
                    />
                </div>
            </Modal>
            <Modal
                show={showMailEditor}
                size='xl'
            >
                <div className="p-3">
                    <div className="form-group mb-2">
                        <label htmlFor="subject" className='mb-1 required'>{t('subject')}</label>
                        <input type="text" name='subject' className='form-control'
                            value={mailSubject}
                            onChange={(e) => {
                                setMailSubject(e.target.value);
                            }}
                        />
                    </div>
                    <FroalaEditor tag='textarea'
                        model={mailContent}
                        onModelChange={(model) => {
                            setMailContent(model);
                        }}
                        config={{
                            placeholderText: t('enter_email'),
                            charCounterCount: true,
                            height: 500,
                            fontSize: ["8", "10", "12", "14", "18", "24", "30", "36", "48"],
                            pluginsEnabled: ["fontSize", "colors", "emoticons", "align", "charCounter", "codeBeautifier", 'codeView', 'fullscreen', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'table', 'entities'],
                        }}
                    />
                </div>
                <div className='m-3'>
                    <Button label={t('send_mail')} className='mx-2'
                        onClick={() => {
                            handleSendBulkEmail();
                        }}
                    />
                    <Button label={t('cancel')} outlined severity='danger'
                        onClick={() => {
                            setShowMailEditor(false);
                        }}
                    />
                </div>
            </Modal>
            <div className="data-table mt-2">
                <DataTable
                    value={students}
                    filters={filters}
                    selection={selectedStudents}
                    onSelectionChange={(e) => {
                        setSelectedStudents(e.value);
                    }}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    header={
                        <div className='row'>
                            <div className="col-md-6">
                                <input className='form-control'
                                    placeholder={t('search')}
                                    onChange={(e) => {
                                        let _filters = { ...filters };
                                        _filters['global'].value = e.target.value;
                                        setFilters(_filters);
                                    }}
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <form action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleInstructorsBulkAction();
                            }}
                        >
                            <div className='row'>
                                <div className="col-md-4">
                                    <Select className='pr-input' required options={actionOptions}
                                        onChange={(e) => {
                                            setAction(e)
                                        }}
                                        placeholder={t('select')}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Button label={t('apply')} type='submit' />
                                </div>
                            </div>
                        </form>
                    }
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column
                        field="code"
                        header={t('code')}
                        sortable
                    />
                    <Column
                        field="alias_name"
                        header={t('alias_name')}
                        sortable
                    />
                    <Column
                        field="email"
                        header={t('email')}
                        sortable
                    />
                    <Column
                        field="phone"
                        header={t('phone')}
                        sortable
                    />
                    <Column
                        field="active"
                        header={t('status')}
                        body={(row) => {
                            return (
                                <InputSwitch checked={row.active === 1 ? true : false} />
                            )
                        }}
                        sortable
                    />
                    <Column
                        header={t('actions')}
                        body={(row) => {
                            return (
                                <div className='d-flex'>
                                    <Button className='icon-btn' severity='info'
                                        onClick={() => {
                                            handleShowStudMoreData(row.id);
                                        }}
                                    >
                                        <span className="material-symbols-outlined">
                                            menu_book
                                        </span>
                                    </Button>
                                    <Link
                                        state={JSON.stringify(row)}
                                        to='/students/view'
                                        className='link-icon-btn mx-1' severity='primary' id="edit-btn">
                                        <span className="material-symbols-outlined">
                                            visibility
                                        </span>
                                    </Link>
                                </div>
                            );
                        }}
                    />

                </DataTable>

            </div>
        </div>
    );
}

export default Students;