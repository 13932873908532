import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getInstructorsForInvite, sendInviationForUsers } from '../../apis/services';
import { Modal } from 'react-bootstrap';
const InviteInstructors = () => {
    const { t } = useTranslation();
    const [ins, setIns] = useState([]);
    const [selectedIns, setSelectedIns] = useState([]);
    const [emails, setEmails] = useState();
    const [emailSheet, setEmailSheet] = useState();
    const [showInviteModal, setShowInviteModal] = useState(false);
    useEffect(() => {
        loadInstructors();
    }, []);
    const loadInstructors = async () => {
        try {
            const res = await getInstructorsForInvite();
            setIns(res.data.instructors);
        } catch (error) {

        }
    }
    // const handleInstructorsInvitation = async () => {
    //     let data = {
    //         instructors: selectedIns.map((i) => i.email),
    //     };
    //     await sendInviationForInstructors(data);
    // }
    const handleInstructorsInvitation = async () => {
        let emailText = emails?.trim();
        const formData= new FormData();
        formData.append('instructors',emailText);
        formData.append('emails_sheet',emailSheet)
        await sendInviationForUsers(formData);
        loadInstructors();
    }
    return (
        <div className="p-3 glass-card">
            <div className="d-flex jcsb">
                <div className='mt-2 mb-2'>
                    <h4>{t('instructors')}</h4>
                </div>
                <div className='p-2'>
                    <Button outlined label={t('invite_ins')}
                        onClick={() => {
                            setShowInviteModal(true);
                        }}
                    />
                </div>
            </div>
            <Modal
                show={showInviteModal}
            >
                <div className="p-3">
                    <div className="d-flex jcsb aic">
                        <h5>{t('email')}</h5>
                        <Button className='icon-btn'
                            onClick={() => {
                                setShowInviteModal(false);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                close
                            </span>
                        </Button>
                    </div>
                    <hr />
                    <div className="form-group">
                        <textarea className='form-control' rows={10}
                            onChange={(e) => {
                                setEmails(e.target.value)
                            }}
                            value={emails}
                            placeholder={t('enter_email_line_by_line')}
                        >

                        </textarea>
                        <div className="form-group mt-2">
                            <label htmlFor="file">{t('emails_sheet')}</label>
                            <input type='file' className='form-control' accept='.xlsx' name='emails_sheet' 
                                onChange={(e)=>{
                                    setEmailSheet(e.target.files[0]);
                                }}
                            />
                        </div>
                        <Button className='p-btn mt-3' label={t('invite_ins')} type='submit'
                            onClick={() => {
                                handleInstructorsInvitation();
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <div className="data-table mt-2">

                <DataTable
                    onRowClick={(row) => {
                        navigator.clipboard.writeText(row.data.email);
                    }}
                    value={ins}
                    paginator
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    selectionMode="checkbox"
                    filterDisplay="menu"
                    emptyMessage={t('data_not_available')}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    selection={selectedIns}
                    onSelectionChange={(e) => {
                        setSelectedIns(e.value);
                    }}
                >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                    {/* <Column
                        field="name"
                        header={t('name')}
                        sortable
                    /> */}
                    <Column
                        field="email"
                        header={t('email')}
                        sortable
                    />
                    {/* <Column
                        field="college"
                        header={t('college')}
                        sortable
                    /> */}
                    <Column
                        field="status"
                        header={t('status')}
                        body={(row) => row.status === 0 ? "Not Invited" : 'Invited Already'}
                        sortable
                    />
                    {/* <Column
                        field="invites_count"
                        header={t('invites_count')}
                        body={(row) => 'xx'}
                        sortable
                    /> */}
                    <Column
                        field="last_invited"
                        header={t('last_invited')}
                        last_invited
                        sortable
                    />
                </DataTable>

            </div>
        </div>
    );
}

export default InviteInstructors;